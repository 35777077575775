// project import
import dashboard from "./dashboard";
import notice from "./notice";
import center from "./center";
import b2bUser from "./b2b/b2bUser";
import b2bInspection from "./b2b/b2bInspection";
import b2bCustomer from "./b2b/b2bCustomer";
import b2cUser from "./b2c/b2cUser";
import b2cInspection from "./b2c/b2cInspection";
import b2cCalculate from "./b2c/b2cCalculate";
import { centerPolicy, userPolicy } from "./policy";
import b2cPointPriceManagment from "./b2c/b2cPointPriceManagment";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // TODO: items 이름 바꾸기
  items: [
    dashboard,
    notice,
    center,
    centerPolicy,
    userPolicy,
    b2bUser,
    b2bInspection,
    b2bCustomer,
    b2cUser,
    b2cInspection,
    b2cCalculate,
    b2cPointPriceManagment,
  ],
};

export default menuItems;
