// third-party
import { FormattedMessage } from "react-intl";

// assets
import { CalendarOutlined } from "@ant-design/icons";

// icons
const icons = {
  CalendarOutlined,
};

const inspection = {
  id: "b2c-book/inspection",
  type: "group",
  children: [
    {
      id: "inspection",
      title: <FormattedMessage id="b2c-book/inspection" />,
      type: "item",
      url: "/b2c/inspection-status",
      icon: icons.CalendarOutlined,
    },
  ],
};

export default inspection;
