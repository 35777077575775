// third-party
import { FormattedMessage } from "react-intl";

// assets
import { LockOutlined } from "@ant-design/icons";

// icons
const icons = {
  LockOutlined,
};

const centerPolicy = {
  id: "center-policy",
  type: "group",
  children: [
    {
      id: "policy",
      title: <FormattedMessage id="center-policy" />,
      type: "collapse",
      icon: icons.LockOutlined,
      children: [
        {
          id: "center-rules",
          title: <FormattedMessage id="center-rules" />,
          type: "item",
          url: "/policy/center/rules",
        },
        {
          id: "center-person",
          title: <FormattedMessage id="center-person" />,
          type: "item",
          url: "/policy/center/person",
        },
        {
          id: "center-location",
          title: <FormattedMessage id="center-location" />,
          type: "item",
          url: "/policy/center/location",
        },
        {
          id: "center-advertisement",
          title: <FormattedMessage id="center-advertisement" />,
          type: "item",
          url: "/policy/center/advertisement",
        },
      ],
    },
  ],
};

const userPolicy = {
  id: "user-policy",
  type: "group",
  children: [
    {
      id: "user-policy",
      title: <FormattedMessage id="user-policy" />,
      type: "collapse",
      icon: icons.LockOutlined,
      children: [
        {
          id: "user-client",
          title: <FormattedMessage id="user-client" />,
          type: "item",
          url: "/policy/user/client",
        },
        {
          id: "user-rules",
          title: <FormattedMessage id="user-rules" />,
          type: "item",
          url: "/policy/user/rules",
        },
        {
          id: "user-location",
          title: <FormattedMessage id="user-location" />,
          type: "item",
          url: "/policy/user/location",
        },
        {
          id: "user-advertisement",
          title: <FormattedMessage id="user-advertisement" />,
          type: "item",
          url: "/policy/user/advertisement",
        },
      ],
    },
  ],
};

export { centerPolicy, userPolicy };
