import useAuth from 'hooks/useAuth';

// material-ui
import { Button } from '@mui/material';

// ==============================|| HEADER CONTENT - LOGOUT ||============================== //

const LogoutButton = () => {
  const { logout } = useAuth();

  const clickLogout = async () => {
    const refresh = window.localStorage.getItem('refresh_token');
    await logout({ refresh });
  };

  return (
    <>
      <Button onClick={clickLogout}>Logout</Button>
    </>
  );
};

export default LogoutButton;
