// third-party
import { FormattedMessage } from "react-intl";

// assets
import { CalculatorOutlined } from "@ant-design/icons";

// icons
const icons = {
  CalculatorOutlined,
};

const b2bCalculate = {
  id: "b2c-calculate",
  type: "group",
  children: [
    {
      id: "b2c-calculate",
      title: <FormattedMessage id="b2c-calculate" />,
      type: "collapse",
      icon: icons.CalculatorOutlined,
      children: [
        {
          id: "b2c-payment-history",
          title: <FormattedMessage id="b2c-payment-history" />,
          type: "item",
          url: "b2c/point-history",
        },
        {
          id: "b2c-bottom-line",
          title: <FormattedMessage id="b2c-bottom-line" />,
          type: "item",
          url: "b2c/bottom-line",
        },
      ],
    },
  ],
};

export default b2bCalculate;
