export const drawerWidth = 260;

export const stepzoneRed = "#ff0000";
export const stepzoneYellow = "#E0CA00";
export const stepzoneGreen = "#008000";
export const stepzoneGray = "#858585";
export const stepzoneBlue = "#001AFF";

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: "/dashboard",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};

export default config;
