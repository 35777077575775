import { Stack } from "@mui/system";
import { Select, MenuItem } from "@mui/material";

const YearMonthSelect = ({ year, setYear, month, setMonth, type = 0 }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
      <Select
        labelId="year"
        defaultValue={-1}
        value={year}
        size={type === 0 ? "small" : "medium"}
        onChange={e => {
          setYear(e.target.value);
        }}
        variant={"outlined"}
        sx={{ width: "100px" }}
      >
        <MenuItem value={-1}>선택안됨</MenuItem>
        <MenuItem value={2023}>2023년</MenuItem>
        <MenuItem value={2024}>2024년</MenuItem>
        <MenuItem value={2025}>2025년</MenuItem>
        <MenuItem value={2026}>2026년</MenuItem>
        <MenuItem value={2027}>2027년</MenuItem>
        <MenuItem value={2028}>2028년</MenuItem>
        <MenuItem value={2029}>2029년</MenuItem>
        <MenuItem value={2030}>2030년</MenuItem>
      </Select>

      <Select
        labelId="month"
        defaultValue={-1}
        value={month}
        size={type === 0 ? "small" : "medium"}
        onChange={e => {
          setMonth(e.target.value);
        }}
        variant={"outlined"}
        sx={{ width: "100px" }}
      >
        <MenuItem value={-1}>선택안됨</MenuItem>
        <MenuItem value={1}>1월</MenuItem>
        <MenuItem value={2}>2월</MenuItem>
        <MenuItem value={3}>3월</MenuItem>
        <MenuItem value={4}>4월</MenuItem>
        <MenuItem value={5}>5월</MenuItem>
        <MenuItem value={6}>6월</MenuItem>
        <MenuItem value={7}>7월</MenuItem>
        <MenuItem value={8}>8월</MenuItem>
        <MenuItem value={9}>9월</MenuItem>
        <MenuItem value={10}>10월</MenuItem>
        <MenuItem value={11}>11월</MenuItem>
        <MenuItem value={12}>12월</MenuItem>
      </Select>
    </Stack>
  );
};

export default YearMonthSelect;
