// material-ui
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// import context

import { useState } from "react";

// import project
import { userAPI } from "../../api";

// ==============================|| USER ADD / EDIT / DELETE ||============================== //

const ChangePasswordModal = ({ onClose }) => {
  const [inputs, setInputs] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });
  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password1: false,
    new_password2: false,
  });
  const [errMessage, setErrMessage] = useState("");

  const handleClickShowPassword = name => {
    // console.log("name", showPassword.name);
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
    console.log("showPassword", showPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const res = await userAPI.passwordChange(inputs);

      if (res.status === 200) {
        alert("비밀번호가 변경되었습니다.");
        onClose();
      }
    } catch (err) {
      const message = Object.values(err.response.data)[0][0];
      setErrMessage(message);
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>비밀번호 변경</DialogTitle>

      <Divider />

      <DialogContent sx={{ p: 2.5 }}>
        <Grid container>
          <Grid item xs>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="title">이전 비밀번호</InputLabel>
                  <OutlinedInput
                    required
                    id="outlined-adornment-old_password"
                    onChange={onChange}
                    name="old_password"
                    type={showPassword.old_password ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={() =>
                            handleClickShowPassword("old_password")
                          }
                          onMouseUp={() =>
                            handleClickShowPassword("old_password")
                          }
                          edge="end"
                        >
                          {showPassword.old_password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="title">변경 비밀번호</InputLabel>
                  <OutlinedInput
                    required
                    id="outlined-adornment-new_password1"
                    onChange={onChange}
                    name="new_password1"
                    type={showPassword.new_password1 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={() =>
                            handleClickShowPassword("new_password1")
                          }
                          onMouseUp={() =>
                            handleClickShowPassword("new_password1")
                          }
                          edge="end"
                        >
                          {showPassword.new_password1 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="title">변경 비밀번호 확인</InputLabel>
                  <OutlinedInput
                    required
                    id="outlined-adornment-new_password2"
                    onChange={onChange}
                    name="new_password2"
                    type={showPassword.new_password2 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={() =>
                            handleClickShowPassword("new_password2")
                          }
                          onMouseUp={() =>
                            handleClickShowPassword("new_password2")
                          }
                          edge="end"
                        >
                          {showPassword.new_password2 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1.25}>
                  <Typography
                    variant="caption"
                    sx={{ color: "red" }}
                    hidden={errMessage === ""}
                  >
                    {errMessage}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 2.5 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button color="error" onClick={onClose}>
                취소
              </Button>
              <Button type="submit" variant="contained">
                비밀번호 변경
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};

export default ChangePasswordModal;
