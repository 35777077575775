import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "config";

// project import
import useAuth from "hooks/useAuth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn, isInitialized, checkLogin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isInitialized) {
      if (!isLoggedIn) {
        navigate("login", { replace: true });
      } else {
        const pathname = location.pathname;
        if (pathname === "/") {
          navigate(config.defaultPath, { replace: true });
        } else {
          checkLogin();
        }
      }
    } else {
      checkLogin(true);
    }
  }, [isLoggedIn, navigate, isInitialized]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
