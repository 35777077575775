// third-party
import { FormattedMessage } from "react-intl";

// assets
import { ToolOutlined } from "@ant-design/icons";

// icons
const icons = {
  ToolOutlined,
};

const center = {
  id: "group-center",
  type: "group",
  children: [
    {
      id: "center",
      title: <FormattedMessage id="center" />,
      type: "item",
      url: "/center",
      icon: icons.ToolOutlined,
    },
  ],
};

export default center;
