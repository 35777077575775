// third-party
import { FormattedMessage } from "react-intl";

// assets
import { NotificationOutlined } from "@ant-design/icons";

// icons
const icons = {
  NotificationOutlined,
};

const notice = {
  id: "group-notice",
  type: "group",
  children: [
    {
      id: "notice",
      title: <FormattedMessage id="notice" />,
      type: "item",
      url: "/notice",
      icon: icons.NotificationOutlined,
    },
  ],
};

export default notice;
