// third-party
import { FormattedMessage } from "react-intl";

// assets
import { CalendarOutlined } from "@ant-design/icons";

// icons
const icons = {
  CalendarOutlined,
};

const b2bInspection = {
  id: "b2b-inspection",
  type: "group",
  children: [
    {
      id: "b2b-inspection",
      title: <FormattedMessage id="b2b-book/inspection" />,
      type: "collapse",
      icon: icons.CalendarOutlined,
      children: [
        {
          id: "b2b-inspection",
          title: <FormattedMessage id="b2b-book/inspection" />,
          type: "item",
          url: "/b2b/inspection-status",
          // breadcrumbs: false, // mainlayout에 적용됨(상위 경로 표시용)
        },
        {
          id: "b2b-calculate",
          title: <FormattedMessage id="b2b-calculate" />,
          type: "item",
          url: "/b2b/calculate",
        },
        {
          id: "b2b-roster",
          title: <FormattedMessage id="b2b-roster" />,
          type: "item",
          url: "/b2b/roster",
        },
        {
          id: "b2b-assignment",
          title: <FormattedMessage id="b2b-assignment" />,
          type: "item",
          url: "/b2b/assignment",
        },
      ],
    },
  ],
};

export default b2bInspection;
