import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/logo";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ padding: 0, justifyContent: "center", mt: 2 }}
    >
      <Logo isIcon={!open} sx={{ width: open ? "auto" : 35, height: 35 }} />
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
