// material-ui
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { useEffect, useState } from "react";
import { province1List } from "constants/provinces";
import YearMonthSelect from "components/jacar/b2b-inspections/YearMonthSelect";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import { b2cPointHistoryApi } from "api";

// ==============================|| Customer Page ||============================== //

const BottomLine = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [province, setProvince] = useState(-1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#e7e9ec",
    },
    [`& .${gridClasses.row}`]: {
      "&.Mui-selected": {
        backgroundColor: "rgba(3, 110, 184, 0.2)",
      },
    },
  }));
  useEffect(() => {
    loadData();
  }, [year, month, province]);

  const loadData = async () => {
    try {
      setLoading(true);
      const { status, data } = await b2cPointHistoryApi.getBottomLine(
        province,
        year,
        month,
      );

      if (status === 200) {
        data.map((item, idx) => {
          item.id = idx;
          return data;
        });
        setRows(data);
        setRowCount(data.length);
      } else {
        alert(`서버에러 : ${data}`);
      }
    } catch (e) {
      alert(`서버에러 : ${e}`);
    } finally {
      setLoading(false);
    }
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarExport
          csvOptions={{
            fileName: `${
              province === -1 ? "전체지역" : province
            } ${year}년 ${month}월 결산내역`,
            delimiter: ",",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  };
  const columns = [
    {
      field: "center_name",
      headerName: "검사소 결산내역",
      width: 200,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "charge_amount_month",
      headerName: "월 충전금액",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "charge_point_amount_month",
      headerName: "월 충전포인트",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "minus_point_amount",
      headerName: "차감 포인트",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "plus_point_amount",
      headerName: "지급 포인트",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "remaining_point_amount",
      headerName: "현재 잔여포인트",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "b2c_inspection_complete_count",
      headerName: "월 검사완료 대수",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
    {
      field: "association_fee",
      headerName: "조합 수수료(1대당 500원)",
      width: 180,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "bottom-line-table-header",
    },
  ];
  return (
    <>
      <Grid container direction="column" spacing={2.75}>
        <Grid item xs>
          <Typography variant="h5">월별 결산 내역</Typography>
        </Grid>
        <Grid item xs>
          <MainCard>
            <ScrollX>
              <Stack
                direction={matchDownSM ? "column" : "row"}
                spacing={1}
                alignItems="center"
                sx={{ p: 1, pb: 0 }}
              >
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-label">
                    지역 선택
                  </InputLabel>
                  <Select
                    value={province}
                    label="지역 선택"
                    onChange={e => setProvince(e.target.value)}
                  >
                    <MenuItem value={-1}>전체</MenuItem>
                    {province1List.map(name => {
                      return <MenuItem value={name}>{name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <YearMonthSelect
                  year={year}
                  setYear={setYear}
                  month={month}
                  setMonth={setMonth}
                  type={1}
                />
              </Stack>

              <Box
                sx={{
                  height: "calc(100vh - 250px)",
                  width: "100%",
                  "& .MuiDataGrid-columnHeaderCheckbox": {
                    backgroundColor: "#2d353c",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#2d353c",
                  },
                  "& .MuiDataGrid-columnHeader svg": {
                    color: "#fff",
                  },
                  "& .bottom-line-table-header": {
                    backgroundColor: "#2d353c",
                    color: "#fff",
                  },
                  "& .bottom-line-table-header div": {
                    justifyContent: "center",
                  },

                  ".MuiDataGrid-sortIcon": {
                    color: "#ffffff",
                    opacity: 1,
                  },
                  ".text-red": {
                    color: "red",
                  },
                  ".text-blue": {
                    color: "blue",
                  },
                  ".text-green": {
                    color: "green",
                  },
                  ".text-orange": {
                    color: "orange",
                  },
                }}
              >
                <StripedDataGrid
                  disableColumnMenu
                  disableSorting
                  pagination
                  loading={loading}
                  headerHeight={40}
                  rowHeight={40}
                  rows={rows}
                  rowCount={rowCount}
                  columns={columns}
                  page={page - 1}
                  pageSize={pageSize}
                  onPageChange={newPage => {
                    setPage(newPage + 1);
                  }}
                  onPageSizeChange={newPageSize => {
                    setPageSize(newPageSize);
                  }}
                  pageSizeOptions={[25, 50, 100]}
                  getCellClassName={params => {
                    if (params.field === "charge_amount_month") {
                      return "text-red";
                    }
                    if (params.field === "charge_point_amount_month") {
                      return "text-red";
                    }
                    if (params.field === "minus_point_amount") {
                      return "text-blue";
                    }
                    if (params.field === "plus_point_amount") {
                      return "text-green";
                    }
                    if (params.field === "association_fee") {
                      return "text-orange";
                    }
                  }}
                  components={{ Toolbar: CustomToolbar }}
                />
              </Box>
            </ScrollX>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default BottomLine;
