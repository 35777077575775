import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// scroll bar
import "simplebar/src/simplebar.css";

// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

// load mock apis
import "api";

// project import
import App from "./App";
import { store } from "store";
import { ConfigProvider } from "contexts/ConfigContext";

const container = document.getElementById("root");
const root = createRoot(container);

// const root = ReactDOM.createRoot(document.getElementById('root'));

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>,
);
