// third-party
import axios from "axios";

// constant
import { JACAR_ADMIN_API_URL } from "../constants";
const api = axios.create({
  baseURL: JACAR_ADMIN_API_URL,
});
api.defaults.headers.common["Content-Type"] = "application/json";

const AUTH_V1_URL = "api/v1/auth";
const NOTICE_V1_URL = "api/v1/notices";
const INSPECTION_V1_URL = "api/v1/inspections";
const BUSINESS_B2B_V1_URL = "api/v1/business";
const BUSINESS_B2C_V1_URL = "api/v1/b2c/users";
const CENTER_V1_URL = "api/v1/centers";
const POINT_V1_URL = "api/v1/point_histories";
const POLICY_V1_URL = "api/v1/policies";
const DASHBOARD_V1_URL = "api/v1/inspections";
const RESERVATION_V1_URL = "api/v1/inspections";
const CAR_LIST_V1_URL = "/api/v1/cars";
const B2C_INSPECTION_V1_URL = "api/v1/b2c_inspections";
const B2C_POINT_PRICE_V1_URL = "api/v1/point_prices";
const B2C_POINT_HISTORY_V1_URL = "api/v1/point_histories";

function getToken() {
  return window.localStorage.getItem("access_token");
}

// auth API
export const userAPI = {
  login: function (data) {
    return api.post(`${AUTH_V1_URL}/login/`, data);
  },
  logout: function (data) {
    return api.post(`${AUTH_V1_URL}/logout/`, data);
  },
  passwordChange: function (data) {
    return api.post(`${AUTH_V1_URL}/password/change/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  refreshToken: function (data) {
    return api.post(`${AUTH_V1_URL}/token/refresh/`, data);
  },
  tokenVerify: function (data) {
    return api.post(`${AUTH_V1_URL}/token/verify/`, data);
  },
};

// notice API
export const noticeAPI = {
  createNotice: function (data) {
    return api.post(`${NOTICE_V1_URL}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  updateNotice: function (data, id) {
    return api.put(`${NOTICE_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  deleteNotice: function (id) {
    return api.delete(`${NOTICE_V1_URL}/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  getNoticeAll: function () {
    return api.get(`${NOTICE_V1_URL}/all/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  getNoticeAllNonHidden: function () {
    return api.get(`${NOTICE_V1_URL}/all_non_hidden/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

// User API
export const bussinessAPI = {
  getB2BUserList: function (page_size, page) {
    return api.get(`${BUSINESS_B2B_V1_URL}/users/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page_size,
        page,
      },
    });
  },
  getB2BUserDetail: function (id) {
    return api.get(`${BUSINESS_B2B_V1_URL}/users/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },

  getB2CUserList: function (page_size, page) {
    return api.get(`${BUSINESS_B2C_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page_size,
        page,
      },
    });
  },
  getB2CUserDetail: function (id) {
    return api.get(`${BUSINESS_B2C_V1_URL}/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

// Cars API
export const carAPI = {
  getUserCarList: function (id) {
    return api.get(`${CAR_LIST_V1_URL}/user/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

// Center API
export const centerAPI = {
  getCenterList: function (pageSize) {
    return api.get(`${CENTER_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page_size: pageSize,
      },
    });
  },
  getCenterListUnverifed: function (pageSize) {
    return api.get(`${CENTER_V1_URL}/unverifed/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page_size: pageSize,
      },
    });
  },
  getCenterDetail: function (id) {
    return api.get(`${CENTER_V1_URL}/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  updateStatusCenterInfoDetail: function (id, payload) {
    return api.put(`${CENTER_V1_URL}/${id}/`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },

  getCenterCostDetail: function (id) {
    return api.get(`${CENTER_V1_URL}/${id}/priceb2c/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  updateCenterCostDetail: function (id, payload) {
    return api.put(`${CENTER_V1_URL}/${id}/priceb2c/`, payload, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },

  getCenterPointHistory: function (params) {
    return api.get(`${POINT_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params,
    });
  },
  updateCenterPointHistory: function (formData) {
    return api.get(`${POINT_V1_URL}/`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + getToken(),
      },
      formData,
    });
  },

  updateStatusCenterPointDetail: function (payload) {
    return api.post(`${POINT_V1_URL}/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  getCenterListBySearch: function (page, page_size, word) {
    return api.get(`${CENTER_V1_URL}/search/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page,
        page_size,
        word,
      },
    });
  },
  getNearestCenter: function (address, date) {
    return api.get(`api/v1/center/nearest/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        address,
        date,
      },
    });
  },
  getCenterAbleTime: function (address, date, center) {
    return api.get(`api/v1/center/able_time/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        address,
        date,
        center,
      },
    });
  },
  getCenterListFromProvince: function (province) {
    return api.get(`api/v1/centers/province/${province}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

const customerApi = axios.create({
  baseURL: JACAR_ADMIN_API_URL + "api/v1/customers",
});
customerApi.defaults.headers.common["Content-Type"] = "application/json";

// Customer API
export const customerAPI = {
  getCustomerList: function () {
    return customerApi.get("/", {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  createCustomer: function (data) {
    return customerApi.post("/", data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  updateCustomer: function (data, id) {
    return customerApi.put(`/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  deleteCustomer: function (id) {
    return customerApi.delete(`/${id}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

// inspection API
export const inspectionApi = {
  createInspectionList: function (inspectionList) {
    return api.post(`${INSPECTION_V1_URL}/`, inspectionList, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  getInspectionList: function (
    page,
    page_size,
    start_date,
    end_date,
    ic_start_date,
    ic_end_date,
    word,
    steps,
    customer,
    center,
    expired_days,
    order_by,
  ) {
    return api.get(`${INSPECTION_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page,
        page_size,
        start_date,
        end_date,
        ic_start_date,
        ic_end_date,
        word,
        steps,
        customer,
        center,
        expired_days,
        order_by,
      },
    });
  },

  updateInspection: function (id, data) {
    return api.put(`${INSPECTION_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  sendNotice: function (id, phone_number) {
    return api.get(`${INSPECTION_V1_URL}/${id}/send/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        phone_number,
      },
    });
  },
  calculationDetail: function (
    start_date,
    end_date,
    ic_start_date,
    ic_end_date,
    customer,
    center,
  ) {
    return api.get(`${INSPECTION_V1_URL}/calculation/detail/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        start_date,
        end_date,
        ic_start_date,
        ic_end_date,
        customer,
        center,
      },
    });
  },
};

// policy API
export const policyAPI = {
  getPolicyContent: function (id) {
    return api.get(`${POLICY_V1_URL}/${id}/`);
  },
  updatePolicyContetn: function (data, id) {
    return api.put(`${POLICY_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

// policy API
export const dashboardAPI = {
  getDashboardData: function (start_date, end_date) {
    return api.get(`${DASHBOARD_V1_URL}/count/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        start_date,
        end_date,
      },
    });
  },
};

// reservation API
export const reservationApi = {
  createReservation: function (
    id,
    pickup_address,
    center,
    reservation_date,
    reservation_time,
    reservation_type,
  ) {
    return api.put(
      `${RESERVATION_V1_URL}/${id}/reservation/`,
      {
        pickup_address,
        center,
        reservation_date,
        reservation_time,
        reservation_type,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      },
    );
  },
  cancelReservation: function (id) {
    return api.put(
      `${RESERVATION_V1_URL}/${id}/reservation/cancel/`,
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      },
    );
  },
};

//assignments API
export const assignmentApi = {
  tempAssignments: function () {
    return api.get(`${INSPECTION_V1_URL}/temp-assignments/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },

  makeAssignments: function (params) {
    return api.put(`${INSPECTION_V1_URL}/assignments/`, params, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
  cancelAssignment: function (inspection_pk, assignment_cancel_reason) {
    return api.put(
      `${INSPECTION_V1_URL}/${inspection_pk}/assignments/cancel/`,
      { assignment_cancel_reason },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      },
    );
  },
  provinceCenters: function (inspection_pk, province) {
    return api.get(
      `${INSPECTION_V1_URL}/${inspection_pk}/temp-assignments/centers/${province}/`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      },
    );
  },
  provinceCentersMany: function (province) {
    return api.get(
      `${INSPECTION_V1_URL}/temp-assignments/centers/${province}/`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      },
    );
  },
};

//b2c inspection API
export const b2cInspectionApi = {
  getInspectionList: function (
    page,
    page_size,
    start_date,
    end_date,
    word,
    steps,
    center,
    order_by,
    user,
  ) {
    return api.get(`${B2C_INSPECTION_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        page,
        page_size,
        start_date,
        end_date,
        word,
        steps,
        center,
        order_by,
        user,
      },
    });
  },

  updateInspection: function (id, data) {
    return api.put(`${B2C_INSPECTION_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

//b2c point price management
export const b2cPointPriceManagementApi = {
  getPointPrices: function () {
    return api.get(`${B2C_POINT_PRICE_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },

  updatePointPrices: function (data) {
    return api.put(`${B2C_POINT_PRICE_V1_URL}/`, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  },
};

export const b2cPointHistoryApi = {
  getBottomLine: function (province, year, month) {
    return api.get(`${B2C_POINT_HISTORY_V1_URL}/bottom_line/`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        province,
        year,
        month,
      },
    });
  },
};
