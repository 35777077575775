// third-party
import { FormattedMessage } from "react-intl";

// assets
import { MoneyCollectOutlined } from "@ant-design/icons";

// icons
const icons = {
  MoneyCollectOutlined,
};

const b2cPointPriceManagement = {
  id: "b2c-point-management",
  type: "group",
  children: [
    {
      id: "point-management",
      title: <FormattedMessage id="b2c-point-management" />,
      type: "item",
      url: "/b2c/point-management",
      icon: icons.MoneyCollectOutlined,
    },
  ],
};

export default b2cPointPriceManagement;
