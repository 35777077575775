// third-party
import { FormattedMessage } from "react-intl";

// assets
import { UsergroupAddOutlined } from "@ant-design/icons";

// icons
const icons = {
  UsergroupAddOutlined,
};

const b2bCustomer = {
  id: "b2b-customer",
  type: "group",
  children: [
    {
      id: "b2b-customer",
      title: <FormattedMessage id="b2b-customer" />,
      type: "item",
      url: "/b2b/customer",
      icon: icons.UsergroupAddOutlined,
    },
  ],
};

export default b2bCustomer;
