import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import BottomLine from "pages/b2c/calculate/bottomLine";

// pages routing
const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500")),
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction")),
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon")),
);

// render - pages
const Dashboard = Loadable(lazy(() => import("pages/dashboard/dashboard")));
const Notice = Loadable(lazy(() => import("pages/notice/notice")));
const B2BUser = Loadable(lazy(() => import("pages/b2b/user/user")));
const B2CUser = Loadable(lazy(() => import("pages/b2c/user/user")));
const B2BUserDetail = Loadable(lazy(() => import("pages/b2b/user/userDetail")));
const B2CUserDetail = Loadable(lazy(() => import("pages/b2c/user/userDetail")));
const Center = Loadable(lazy(() => import("pages/center/center")));
const CenterDetail = Loadable(lazy(() => import("pages/center/centerDetail")));

const B2BInspection = Loadable(
  lazy(() => import("pages/b2b/inspection/inspectionStatus")),
);
const B2CInspection = Loadable(
  lazy(() => import("pages/b2c/inspection/inspectionStatus")),
);
const Calculation = Loadable(
  lazy(() => import("pages/b2b/inspection/calculation")),
);
const Roster = Loadable(lazy(() => import("pages/b2b/inspection/roster")));
const Assignment = Loadable(
  lazy(() => import("pages/b2b/inspection/assignment")),
);
const Customer = Loadable(lazy(() => import("pages/b2b/customer/customer")));
const CenterPolicy = Loadable(lazy(() => import("pages/policy/center/policy")));
const PointHistory = Loadable(
  lazy(() => import("pages/b2c/calculate/calculate")),
);
const UserPolicy = Loadable(lazy(() => import("pages/policy/user/policy")));
const AlimtokPointManagment = Loadable(
  lazy(() => import("pages/b2c/PointPriceManagment/PointPriceManagment")),
);

// ==============================|| MAIN ROUTING ||============================== //

// TODO: AuthGuard 때문에 처음에 로그인화면으로 계속감 => url 직접 기입, LINK 작동이 안됨
// TODO: AuthGuard 여기에 있던 리덕스 기능 다 뺴고 useEffect로 token 체크?? => 필요없긴할듯 어차피 데이터 받을떄 token 유효검사하고 login page로 보내주니깐??
const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "notice",
          element: <Notice />,
        },
        {
          path: "center",
          element: <Center />,
        },
        {
          path: "center/detail/:id",
          element: <CenterDetail />,
        },
        {
          path: "policy",
          children: [
            {
              path: "center",
              children: [
                {
                  path: "rules",
                  element: <CenterPolicy />,
                },
                {
                  path: "person",
                  element: <CenterPolicy />,
                },
                {
                  path: "location",
                  element: <CenterPolicy />,
                },
                {
                  path: "advertisement",
                  element: <CenterPolicy />,
                },
                {
                  path: "client",
                  element: <CenterPolicy />,
                },
              ],
            },
            {
              path: "user",
              children: [
                {
                  path: "rules",
                  element: <UserPolicy />,
                },
                {
                  path: "person",
                  element: <UserPolicy />,
                },
                {
                  path: "location",
                  element: <UserPolicy />,
                },
                {
                  path: "advertisement",
                  element: <UserPolicy />,
                },
                {
                  path: "client",
                  element: <UserPolicy />,
                },
              ],
            },
          ],
        },
        {
          path: "b2b",
          children: [
            {
              path: "user",
              element: <B2BUser />,
            },
            {
              path: "user/detail/:id",
              element: <B2BUserDetail />,
            },
            {
              path: "inspection-status",
              element: <B2BInspection />,
            },
            {
              path: "calculate",
              element: <Calculation />,
            },
            {
              path: "roster",
              element: <Roster />,
            },
            {
              path: "assignment",
              element: <Assignment />,
            },
            {
              path: "customer",
              element: <Customer />,
            },
          ],
        },
        {
          path: "b2c",
          children: [
            {
              path: "user",
              element: <B2CUser />,
            },
            {
              path: "user/detail/:id",
              element: <B2CUserDetail />,
            },
            {
              path: "inspection-status",
              element: <B2CInspection />,
            },

            {
              path: "point-history",
              element: <PointHistory />,
            },
            {
              path: "bottom-line",
              element: <BottomLine />,
            },
            {
              path: "point-management",
              element: <AlimtokPointManagment />,
            },
          ],
        },
      ],
    },

    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;
