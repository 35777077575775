// third-party
import { TrySharp } from "@mui/icons-material";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
// import { useNavigate } from 'react-router-dom';

// action - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import { userAPI } from "../api";

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| LOGIN CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const goLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    dispatch({
      type: LOGOUT,
    });
  };

  const goLogin = (access, refresh) => {
    window.localStorage.setItem("access_token", access);
    window.localStorage.setItem("refresh_token", refresh);
    dispatch({
      type: LOGIN,
    });
  };

  const checkLogin = async (isRefresh = false) => {
    const accessToken = window.localStorage.getItem("access_token");
    const refreshToken = window.localStorage.getItem("refresh_token");

    if (accessToken) {
      try {
        const { status } = await userAPI.tokenVerify({ token: accessToken });
        if (status === 200) {
          //access token 유효함 - 로그인 성공
          if (isRefresh) {
            const { status, data } = await userAPI.refreshToken({
              refresh: refreshToken,
            });
            if (status === 200) {
              const { access, refresh } = data;
              goLogin(access, refresh);
            } else {
              goLogout();
            }
          } else {
            dispatch({
              type: LOGIN,
            });
          }
        } else {
          goLogout();
        }
      } catch (e) {
        //access token 유효하지 않음
        //refresh token 유효성 검사
        try {
          const { status } = await userAPI.tokenVerify({ token: refreshToken });
          if (status === 200) {
            //access token 재발급
            const { status, data } = await userAPI.refreshToken({
              refresh: refreshToken,
            });
            if (status === 200) {
              const { access, refresh } = data;
              goLogin(access, refresh);
            } else {
              goLogout();
            }
          }
        } catch (e) {
          goLogout();
        }
      }
    } else {
      goLogout();
    }
  };

  const login = () => {};

  const logout = async inputs => {
    try {
      await userAPI.logout(inputs);
    } finally {
      goLogout();
    }
  };

  const verifyToken = async () => {
    const refreshToken = window.localStorage.getItem("refresh_token");
    const res = await userAPI.tokenVerify({
      token: refreshToken,
    });

    return res.status === 200;
  };

  const updateToken = async () => {
    const refreshToken = window.localStorage.getItem("refresh_token");
    await userAPI
      .refreshToken({
        refresh: refreshToken,
      })
      .then(res => {
        if (res.status === 200) {
          const { access_token, refresh_token } = res.data;
          window.localStorage.setItem("refresh_token", refresh_token);
          window.localStorage.setItem("access_token", access_token);
        }
      });
  };

  const passwordChange = async () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        goLogin,
        goLogout,
        updateToken,
        verifyToken,
        passwordChange,
        checkLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
