import { useState } from "react";

// material-ui
import { Typography, Box, Dialog, Button, Modal } from "@mui/material";

// import project
import ChangePasswordModal from "components/modal/ChangePasswordModal";
import MainCard from "components/MainCard";

// ==============================|| HEADER CONTENT - LOGOUT ||============================== //

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>비밀번호 변경</Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        <ChangePasswordModal onClose={handleClose} />
      </Dialog>

      {/* <Button onClick={changePassword}>비밀번호 변경</Button> */}
    </>
  );
};

export default ChangePassword;
