// material-ui
import { Box, Button } from "@mui/material";

// project import
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      width="100%"
    >
      {/* {matchesXs && <MobileSection />} */}

      <ChangePassword />
      <Logout />
    </Box>
  );
};

export default HeaderContent;
