// third-party
import { FormattedMessage } from "react-intl";

// assets
import { UserOutlined } from "@ant-design/icons";

// icons
const icons = {
  UserOutlined,
};

const b2cUser = {
  id: "b2c-user",
  title: <FormattedMessage id="b2c" />,
  type: "group",
  children: [
    {
      id: "b2c-user",
      title: <FormattedMessage id="b2c-user" />,
      type: "item",
      url: "/b2c/user",
      icon: icons.UserOutlined,
      breadcrumbs: false,
    },
  ],
};

export default b2cUser;
